import React from 'react';
import { Helmet } from 'react-helmet';
import { createStyles, StyleRules, Theme } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import Layout from '../../layout/AdminLayout';
import JobRequestListView from '../../views/admin/JobRequestListView';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    toolbar: {
      width: 1128,
      margin: 'auto',
      height: theme.spacing(3),
    },
    img: {
      width: 40,
      marginBottom: 0,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
  });

const JobRequest = (): React.ReactElement => {
  return (
    <Layout>
      {/* Header */}
      <Helmet title="工单管理"></Helmet>

      {/* Body */}
      <JobRequestListView />
    </Layout>
  );
};

export interface Props extends WithStyles<typeof styles> {
  title?: string;
}

export default withStyles(styles)(JobRequest);
